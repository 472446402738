<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbs" class="pl-0 pb-1"></v-breadcrumbs>
    <h1 class="mt-0 mb-2">{{ $metaInfo.title }}</h1>
    <v-sheet class="pa-4">
      <v-row>
        <v-col md="6">
          <validation-observer
            ref="form"
            tag="form"
            class="d-flex mt-1"
            @submit.prevent="handleSubmit"
          >
            <validation-provider v-slot="{ errors }" slim rules="required">
              <v-file-input
                v-model="file"
                outlined
                counter
                dense
                show-size
                truncate-length="15"
                class="mr-2"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :error-messages="errors"
              ></v-file-input>
            </validation-provider>
            <v-btn color="primary" type="submit" :loading="pending"
              >Отправить</v-btn
            >
          </validation-observer>
          <div v-if="hasValidateErrors" class="my-3">
            <p>
              <strong class="error--text"
                >Ошибки в файле ({{ hasValidateErrors }})</strong
              >. Пожалуйста, исправьте ошибки и загрузите файл повторно.
            </p>
            <ul>
              <li v-for="(value, key) in validateErrors" :key="key">
                <div class="text-caption">Строка № {{ +key + 1 }}</div>
                <div class="text-caption error--text">
                  {{ value.join(", ") }}
                </div>
              </li>
            </ul>
          </div>
          <div v-if="error" class="error--text my-3">{{ error }}</div>
        </v-col>
        <v-col md="6">
          <p>
            Вы можете импортировать таблицу с тегированными пользователями. Теги
            из этой таблицы, будут сопоставлены с тегами в базе данных. Все
            несуществующие теги будут созданы.
          </p>

          <p class="mb-0"><strong>Требования к файлу</strong></p>
          <ul>
            <li>Файл формата <strong>.XSLX</strong></li>
            <li>
              Колонки таблицы <strong>не должны</strong> содержать заголовков.
            </li>
            <li>
              Первый столбец &mdash; <strong>Talent ID</strong> пользователя
              (число).
            </li>
            <li>
              Второй столбец &mdash; имена тегов, разделенные точкой с запятой
              "<strong>;</strong>"
            </li>
            <li>
              Каждый тег должен иметь длину от
              <strong>{{ MIN_TAG_NAME }}</strong> до
              <strong>{{ MAX_TAG_NAME }}</strong> символов.
            </li>
            <li>
              Максимальное количество строк таблицы: <strong>10000</strong>
            </li>
          </ul>
          <a href="/files/tags_import_example.xlsx" download>Пример файла</a>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
import { MIN_TAG_NAME, MAX_TAG_NAME } from "@/constants";
const initialState = () => {
  return {
    file: null,
    pending: false,
    error: "",
    validateErrors: {},
  };
};
export default {
  name: "TagsImport",
  data() {
    return {
      ...initialState(),
      MIN_TAG_NAME,
      MAX_TAG_NAME,
    };
  },
  metaInfo() {
    return {
      title: `Импорт тегов`,
    };
  },
  computed: {
    breadCrumbs() {
      return [
        {
          exact: true,
          to: {
            name: "tags",
          },
          text: "Теги",
        },
        {
          disabled: true,
          text: this.$metaInfo.title,
        },
      ];
    },
    hasValidateErrors() {
      return Object.keys(this.validateErrors).length;
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      Object.assign(this.$data, initialState());
    },
    async handleSubmit() {
      const { file } = this;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      this.validateErrors = {};
      this.error = "";
      const fd = new FormData();
      fd.append("file", file);
      fd.append("file_type", "xlsx");
      let uuid;
      try {
        const { data } = await apiClient.post(
          "/contacts/tags/import/validate",
          fd
        );
        if (data.contains_errors) {
          this.validateErrors = data.rows.reduce((acc, value, idx) => {
            if (value.error) {
              acc[idx] = value.errors;
            }
            return acc;
          }, {});
          this.pending = false;
          return;
        } else {
          uuid = data.uuid;
        }
      } catch (error) {
        this.pending = false;
        this.error = `Не удалось проверить файл. ${error.message}`;
        return;
      }
      if (uuid) {
        try {
          await apiClient.post(`/contacts/tags/import/${uuid}/process`);
          this.$toast("Данные успешно импортированы.", { type: "success" });
          this.reset();
        } catch (error) {
          this.error = `Не удалось импортировать данные. ${error.message}`;
          console.log("error", error);
        }
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
