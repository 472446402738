<template>
  <v-card :loading="pending">
    <v-card-title class="text-h5 rm-dialog-title">
      <span v-if="isEdit">Редактировать тег</span>
      <span v-else>Создать тег</span>
    </v-card-title>

    <v-card-text class="pb-1">
      <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="tag_name"
          :rules="nameValidation"
          vid="tag_name"
        >
          <v-text-field
            v-model.trim="form.name"
            :error-messages="errors"
            label="Название тега"
          ></v-text-field>
        </validation-provider>
        <h4 class="my-2">Цвет тега:</h4>
        <v-color-picker
          v-model="form.color"
          dot-size="32"
          hide-inputs
          hide-mode-switch
          show-swatches
          width="500"
          swatches-max-height="181"
        ></v-color-picker>
      </validation-observer>
      <div v-if="error" class="mt-3 error--text">
        Не удалось сохранить тег. {{ error }}
      </div>
    </v-card-text>

    <v-card-actions class="pt-0">
      <v-btn color="green darken-1" text @click="handleSubmit">
        Сохранить
      </v-btn>
      <v-btn text @click="$emit('close')">Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { apiClient } from "@/api";
import { MAX_TAG_NAME, MIN_TAG_NAME } from "@/constants";
const initialForm = () => {
  return { color: "#ff0000", name: "" };
};
export default {
  name: "CreateTag",
  props: {
    tag: {
      type: Object,
      default: null,
      validator(value) {
        return !!value.id && !!value.color && !!value.name;
      },
    },
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      pending: false,
      form: initialForm(),
      error: "",
    };
  },
  computed: {
    isEdit() {
      return !!this.tag?.id;
    },
    nameValidation() {
      return {
        min: MIN_TAG_NAME,
        max: MAX_TAG_NAME,
        required: true,
      };
    },
  },
  watch: {
    isActive: {
      handler(val) {
        if (!val) {
          this.reset();
        }
      },
    },
    tag: {
      deep: true,
      immediate: true,
      handler(val, prev) {
        if (val) {
          this.form.color = val.color || initialForm().color;
          this.form.name = val.name || "";
          this.error = "";
        } else if (!val && prev) {
          this.reset();
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      if (this.pending) return;
      this.error = "";
      const { isEdit } = this;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      let url = "/tags";
      try {
        const { data } = await apiClient({
          method: isEdit ? "PATCH" : "POST",
          url: isEdit ? `${url}/${this.tag.id}` : url,
          data: {
            ...this.form,
          },
        });
        this.$emit("onSubmit", data);
        this.reset();
        this.$emit("close");
      } catch (error) {
        console.log("error.originalData", error.originalData);
        if (
          Array.isArray(error.originalData) &&
          error.originalData.some((e) => e.validation_error === "unique")
        ) {
          this.error =
            "Тег с таким названием уже сесть в базе данных. Укажите другое название";
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
    reset() {
      this.form = initialForm();
      this.error = "";
      this.$refs.form?.reset();
    },
  },
};
</script>

<style></style>
