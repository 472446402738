<template>
  <v-container>
    <h1>Управление тегами</h1>
    <div class="mt-2">
      <v-btn color="primary" class="mr-2" @click="handleOpenTagDialog(null)"
        ><v-icon left>mdi-tag</v-icon> Создать тег</v-btn
      >
      <v-btn color="primary" outlined :to="{ name: 'tags_import' }"
        ><v-icon left>mdi-tag</v-icon> Импорт тегов</v-btn
      >
    </div>

    <v-sheet class="pa-4 my-4">
      <v-row class="mb-3">
        <v-col md="6" sm="10" xs="12">
          <v-text-field
            v-model.trim="search"
            label="Поиск тега"
            clearable
            hint="Начните вводить название"
            prepend-inner-icon="mdi-magnify"
            flat
            :loading="pending"
            class="mr-4 ml-2 pt-5 search"
            @click:clear="page = 1"
          />
        </v-col>
      </v-row>
      <div class="tags-grid">
        <div v-for="item in list" :key="item.id">
          <v-chip
            :color="item.color"
            outlined
            class="mr-2 mb-2"
            dark
            :title="`Показать всех пользователей с тегом «${item.name}»`"
            @click.stop="
              $router.push({
                name: 'search',
                query: {
                  tags_ids: item.id,
                  search: '',
                },
              })
            "
          >
            {{ item.name }}
            <v-icon
              right
              title="Редактировать тег"
              @click.stop="handleOpenTagDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              right
              title="Удалить тег"
              @click.stop="handleRemoveTag(item.id)"
              >mdi-delete</v-icon
            >
          </v-chip>
        </div>
      </div>
    </v-sheet>

    <div class="my-2">
      <v-pagination
        v-if="pagesCount > 1"
        v-model="page"
        :total-visible="7"
        :length="pagesCount"
      ></v-pagination>
    </div>

    <div v-if="showClearMessage">
      Теги не найдены.
      <a
        href="#"
        class="link link--pseudo"
        @click.prevent="handleOpenTagDialog(null, true)"
        >Создайте новый тег</a
      >
      или
      <a href="#" class="link link--pseudo" @click.prevent="handleClearFilters"
        >очистите поиск</a
      >
    </div>

    <div v-if="error" class="error--text">{{ error }}</div>

    <v-dialog v-model="tagDialog" max-width="500px" persistent>
      <tag-dialog
        :tag="selectedTag"
        @onSubmit="handleUpdateTag"
        @close="handleCloseTagDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
import TagDialog from "@/components/dialogs/CreateTag.vue";
import debounce from "lodash/debounce";

export default {
  name: "Tags",
  components: {
    TagDialog,
  },
  data() {
    return {
      pending: false,
      total: 0,
      size: 40,
      page: 1,
      list: [],
      tagDialog: false,
      selectedTag: null,
      search: "",
      error: null,
    };
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.total / this.size);
    },
    showClearMessage() {
      return !this.pending && !this.list.length && !this.error && this.search;
    },
  },

  watch: {
    page: {
      handler() {
        this.getList();
      },
    },
    search: {
      handler(val) {
        if (!val) {
          this.page = 1;
        }
        this.debouncedTagSearch();
      },
    },
  },
  created() {
    this.debouncedTagSearch = debounce(this.getList, 300);
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCloseTagDialog() {
      this.selectedTag = null;
      this.tagDialog = false;
    },
    handleOpenTagDialog(tag, needFiltersReset) {
      if (needFiltersReset) {
        this.handleClearFilters();
      }
      if (tag) {
        this.selectedTag = { ...tag };
      } else {
        this.selectedTag = null;
      }
      this.tagDialog = true;
    },
    handleUpdateTag(tag) {
      if (!tag) return;
      const existingTag = this.list.find((n) => n.id === tag.id);
      if (!existingTag) {
        this.list.unshift(tag);
      } else {
        existingTag.name = tag.name;
        existingTag.color = tag.color;
      }
    },
    async handleRemoveTag(id) {
      try {
        await apiClient({
          method: "DELETE",
          url: `/tags/${id}`,
        });
        await this.getList();
      } catch (error) {
        this._showError(`Не удалось удалить тег ${id}. ${error.message}`);
      }
    },
    async getList() {
      const { search } = this;
      this.error = null;
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/tags",
          params: {
            limit: this.size,
            offset: (this.page - 1) * this.size,
            ...(search && { search }),
          },
        });
        this.list = data.results;
        this.total = data.count;
      } catch (error) {
        this.error = error.message;
        console.log("error", error);
      }
      this.pending = false;
    },
    handleClearFilters() {
      this.page = 1;
      this.search = "";
    },
  },
};
</script>

<style lang="scss">
.tags-grid {
  column-count: 3;
  column-gap: 40px;

  @media screen and (max-width: 1200px) {
    column-count: 2;
    column-gap: 40px;
  }

  @media screen and (max-width: 640px) {
    column-count: 1;
    column-gap: 0px;
  }
}
</style>
