var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v("Управление тегами")]),_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleOpenTagDialog(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" Создать тег")],1),_c('v-btn',{attrs:{"color":"primary","outlined":"","to":{ name: 'tags_import' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" Импорт тегов")],1)],1),_c('v-sheet',{staticClass:"pa-4 my-4"},[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"md":"6","sm":"10","xs":"12"}},[_c('v-text-field',{staticClass:"mr-4 ml-2 pt-5 search",attrs:{"label":"Поиск тега","clearable":"","hint":"Начните вводить название","prepend-inner-icon":"mdi-magnify","flat":"","loading":_vm.pending},on:{"click:clear":function($event){_vm.page = 1}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)],1),_c('div',{staticClass:"tags-grid"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id},[_c('v-chip',{staticClass:"mr-2 mb-2",attrs:{"color":item.color,"outlined":"","dark":"","title":`Показать всех пользователей с тегом «${item.name}»`},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'search',
              query: {
                tags_ids: item.id,
                search: '',
              },
            })}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{attrs:{"right":"","title":"Редактировать тег"},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenTagDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"right":"","title":"Удалить тег"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveTag(item.id)}}},[_vm._v("mdi-delete")])],1)],1)}),0)],1),_c('div',{staticClass:"my-2"},[(_vm.pagesCount > 1)?_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.pagesCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),(_vm.showClearMessage)?_c('div',[_vm._v(" Теги не найдены. "),_c('a',{staticClass:"link link--pseudo",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleOpenTagDialog(null, true)}}},[_vm._v("Создайте новый тег")]),_vm._v(" или "),_c('a',{staticClass:"link link--pseudo",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleClearFilters.apply(null, arguments)}}},[_vm._v("очистите поиск")])]):_vm._e(),(_vm.error)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.tagDialog),callback:function ($$v) {_vm.tagDialog=$$v},expression:"tagDialog"}},[_c('tag-dialog',{attrs:{"tag":_vm.selectedTag},on:{"onSubmit":_vm.handleUpdateTag,"close":_vm.handleCloseTagDialog}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }